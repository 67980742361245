import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import PageLink from "../components/pageLink"
import Layout from "../components/layout"
import VideoModal from "../components/videoModal"

import "./polaroid.scss"
import { onBackToMain, BASE_TRANSITION_LENGTH } from "../transitions"

import Polaroid from "../images/polaroid.webp"
import PolaroidNoWebp from "../images/polaroid.png"

import { onPreload } from "../assets"
import { isSafari, workaroundOverscrollPrevention } from "../util"
import { useWebPSupport } from "../useWebpSupport"
import { VideoThumbnail } from "../components/videoThumbnail"

const Director = ({ data, transitionStatus }) => {
  const scrollWrapperRef = useRef()
  const videos = data.markdownRemark.frontmatter.videos
  const [viewingVideo, setViewingVideo] = useState(null)
  const [preloaded, setPreloaded] = useState(false)
  const webp = useWebPSupport()

  useEffect(() => {
    let mounted = true
    onPreload(() => mounted && setPreloaded(true))
    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    if (isSafari()) {
      let onScroll = () =>
        workaroundOverscrollPrevention(scrollWrapperRef.current)
      scrollWrapperRef.current.addEventListener("scroll", onScroll)
      return () => {
        scrollWrapperRef.current.removeEventListener("scroll", onScroll)
      }
    }
  }, [])

  return (
    <Layout transitionStatus={transitionStatus} page="director polaroid">
      <Helmet
        bodyAttributes={{
          class: "page director",
        }}
        key="helmet"
      />
      <div className="scrollWrapper" ref={scrollWrapperRef}>
        <div className="scrollContent">
          {videos && (
            <div className="videos">
              {videos.map(video => (
                <div
                  key={video.id}
                  className="video"
                  onClick={() => setViewingVideo(video)}
                >
                  <img
                    className="videoPolaroid"
                    src={webp ? Polaroid : PolaroidNoWebp}
                    alt="Polaroid photo frame"
                  />
                  <VideoThumbnail video={video} />
                  <p className="videoMeta">{video.client}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <PageLink
        to="/directors"
        className="transitionLink transitionLink--back"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
        }}
        entry={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 10,
          trigger: args => onBackToMain("director", args),
        }}
      ></PageLink>
      {viewingVideo && (
        <VideoModal
          videoId={viewingVideo.id}
          onClose={() => setViewingVideo(null)}
        />
      )}
    </Layout>
  )
}

export default Director

export const pageQuery = graphql`
  query DirectorPage($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        videos {
          client
          id
          title
          titleImage
          thumbnail {
            scale
            xPos
            yPos
          }
        }
      }
    }
  }
`
